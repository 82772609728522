import { useRef, useState } from 'react'
import { custApi } from '@root/api/ump/cust'
import { Balances } from '@open-api/ump/cust'
import { uuid } from '@root/utils/generateUuid'

interface GetBalanceCustReqProps {
  zone: 'b' | 'anonymous'
  scope: string
  customerAccount?: string
  customerPhone?: string
  options?: any
  onSuccess?: (data: Balances) => void
  onError?: (error) => void
}

export const useGetBalanceCust = () => {
  const [isLoading, setIsLoading] = useState(false)
  const getCustBalanceAbortController = useRef(new AbortController())

  const cancelGetBalanceCustRequest = () => {
    getCustBalanceAbortController.current.abort()
    getCustBalanceAbortController.current = new AbortController()
  }

  const getBalance = async ({
    zone,
    scope,
    customerAccount,
    customerPhone,
    options,
    onSuccess,
    onError,
  }: GetBalanceCustReqProps) => {
    getCustBalanceAbortController.current.abort()
    getCustBalanceAbortController.current = new AbortController()

    setIsLoading(true)

    try {
      const { data } = await custApi.getBalance(zone, uuid(), scope, customerAccount, customerPhone, {
        ...options,
        signal: getCustBalanceAbortController.current.signal,
      })

      // eslint-disable-next-line no-underscore-dangle
      if (data._embedded.balances[0]) {
        // eslint-disable-next-line no-underscore-dangle
        if (onSuccess) onSuccess(data._embedded.balances[0])
      } else {
        onError(null)
      }
    } catch (error) {
      if (onError && error?.code !== 'ERR_CANCELED') onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getBalance, cancelGetBalanceCustRequest, isLoading }
}
