import { useRef, useState } from 'react'
import { uprsRecommendationApi } from '@root/api/ump/uprs-recommendation'
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation'

interface GetBalanceCustReqProps {
  customerPhone?: string
  options?: any
  onSuccess?: (data: AmountRecommendationResponse) => void
  onError?: (error) => void
}

export const useGetRecommendedAmountByPhone = () => {
  const [isLoading, setIsLoading] = useState(false)
  const getGetRecommendedAmountAbortController = useRef(new AbortController())

  const cancelGetGetRecommendedAmountRequest = () => {
    getGetRecommendedAmountAbortController.current.abort()
    getGetRecommendedAmountAbortController.current = new AbortController()
  }

  const getRecommendedAmountByPhone = async ({
    customerPhone,
    options,
    onSuccess,
    onError,
  }: GetBalanceCustReqProps) => {
    getGetRecommendedAmountAbortController.current.abort()
    getGetRecommendedAmountAbortController.current = new AbortController()

    setIsLoading(true)

    try {
      const { data } = await uprsRecommendationApi.getRecommendedPaymentAmount(customerPhone, {
        ...options,
        signal: getGetRecommendedAmountAbortController.current.signal,
      })

      if (data) {
        if (onSuccess) onSuccess(data)
      } else {
        onError(null)
      }
    } catch (error) {
      if (onError && error?.code !== 'ERR_CANCELED') onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getRecommendedAmountByPhone, cancelGetGetRecommendedAmountRequest, isLoading }
}
