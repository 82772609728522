import { useRef, useState } from 'react'
import { uprsRecommendationApi } from '@root/api/ump/uprs-recommendation'
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation'

interface GetBalanceCustReqProps {
  customerAccount?: string
  serviceId?: string
  options?: any
  onSuccess?: (data: AmountRecommendationResponse) => void
  onError?: (error) => void
}

export const useGetRecommendedAmountByAccount = () => {
  const [isLoading, setIsLoading] = useState(false)
  const getGetRecommendedAmountByAccountAbortController = useRef(new AbortController())

  const cancelGetGetRecommendedAmountByAccountRequest = () => {
    getGetRecommendedAmountByAccountAbortController.current.abort()
    getGetRecommendedAmountByAccountAbortController.current = new AbortController()
  }

  const getRecommendedAmountByAccount = async ({
    customerAccount,
    serviceId,
    options,
    onSuccess,
    onError,
  }: GetBalanceCustReqProps) => {
    getGetRecommendedAmountByAccountAbortController.current.abort()
    getGetRecommendedAmountByAccountAbortController.current = new AbortController()

    setIsLoading(true)

    try {
      const { data } = await uprsRecommendationApi.getRecommendedPaymentAmountByAccountNumber(
        customerAccount,
        serviceId,
        {
          ...options,
          signal: getGetRecommendedAmountByAccountAbortController.current.signal,
        }
      )

      if (data) {
        if (onSuccess) onSuccess(data)
      } else {
        onError(null)
      }
    } catch (error) {
      if (onError && error?.code !== 'ERR_CANCELED') onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getRecommendedAmountByAccount, cancelGetGetRecommendedAmountByAccountRequest, isLoading }
}
