import React, { FC, useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { MoneyInput } from '@mtsbank/ui-kit';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { Payee } from '@open-api/ump/catalog-manager';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { useGetBalanceCust } from '@root/hooks/useGetBalanceCust';
import { useGetRecommendedAmountByPhone } from '@root/hooks/useGetRecommendedAmountByPhone';
import { MOBILE_PARAM_NAME } from '../../constants';
import { PhoneField } from '../../formFields/PhoneField';
import { FieldWrapper, FieldsWrapper } from './styled';
interface Props {
  setPayeeData?: (payee: Payee) => void;
  setRecommendedAmount: (recomendation: AmountRecommendationResponse) => void;
  field: FieldProps;
}
export const MtsPhoneFields: FC<Props> = ({
  setRecommendedAmount,
  field
}) => {
  const {
    values,
    errors
  } = useFormikContext<WithoutAuthFormValues>();
  const [currentBalance, setCurrentBalance] = useState(null);
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  const zone = getPcekZone();
  const {
    getBalance,
    cancelGetBalanceCustRequest
  } = useGetBalanceCust();
  const {
    getRecommendedAmountByPhone,
    cancelGetGetRecommendedAmountRequest
  } = useGetRecommendedAmountByPhone();
  const isValidPhone = useMemo(() => !errors[MOBILE_PARAM_NAME] && formatPhoneNumber((values[field.name] as string), FormatAliasPhone.DIGIT11), [errors, field.name, values]);

  // отслеживает изменение поля "Номер телефона"
  useEffect(() => {
    cancelGetBalanceCustRequest();
    cancelGetGetRecommendedAmountRequest();
    if (isValidPhone && isAuth) {
      const formattedNumber = formatPhoneNumber((values[field.name] as string), FormatAliasPhone.DIGIT11);
      debounceGetRecommendedAmount(formattedNumber);
      debouncedGetBalance(formattedNumber);
    } else {
      setCurrentBalance(null);
    }
  }, [values[field.name]]);

  // запрашивает рекомендованную сумму к оплату
  const debounceGetRecommendedAmount = debounce((phone: string) => {
    getRecommendedAmountByPhone({
      customerPhone: phone,
      onSuccess: data => setRecommendedAmount(data),
      onError: () => setRecommendedAmount(null)
    });
  }, 700, {
    leading: false,
    trailing: true
  });

  // запрашивает текущий баланс
  const debouncedGetBalance = debounce((phone: string) => getBalance({
    zone,
    scope: 'ALL',
    customerPhone: phone,
    onSuccess: data => setCurrentBalance(data?.accBalances[0]?.total?.amount),
    onError: () => setCurrentBalance(null)
  }), 700, {
    leading: false,
    trailing: true
  });
  return <FieldsWrapper>
      <FieldWrapper>
        <Field {...field} component={PhoneField} size="lg" />
      </FieldWrapper>
      {isAuth && <FieldWrapper>
          <Field allowDecimal readOnly value={currentBalance} component={MoneyInput} label="Текущий баланс" />
        </FieldWrapper>}
    </FieldsWrapper>;
};