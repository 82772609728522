import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { MoneyInput, Text, theme } from '@mtsbank/ui-kit';
import { WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { uuid } from '@root/utils/generateUuid';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { ewcatApi } from '@root/api/ump/ewcat';
import { catalogManagerApi } from '@root/api/ump/catalog-manager';
import { Payee } from '@open-api/ump/catalog-manager';
import { Content } from '@root/components/Content/Content';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { useGetBalanceCust } from '@root/hooks/useGetBalanceCust';
import { useGetRecommendedAmountByPhone } from '@root/hooks/useGetRecommendedAmountByPhone';
import { MOBILE_PARAM_NAME } from '../../constants';
import { configPaymentWithoutAuth } from '../../config/configPaymentWithoutAuth';
import { PhoneField } from '../../formFields/PhoneField';
import { isMtsPhone } from '../../utils/helpers';
import { FieldWrapper, FieldsWrapper } from './styled';
interface Props {
  setPayeeData?: (payee: Payee) => void;
  setRecommendedAmount: (recomendation: AmountRecommendationResponse) => void;
}
export const PhoneTspField: FC<Props> = ({
  setPayeeData,
  setRecommendedAmount
}) => {
  const {
    values,
    errors
  } = useFormikContext<WithoutAuthFormValues>();
  const {
    operatorSelection
  } = configPaymentWithoutAuth;
  const field = {
    name: 'mobilianyi_telefon',
    label: 'Номер телефона',
    placeholder: '+7 000 000-00-00',
    isHidden: false,
    isClearable: false,
    readOnly: false,
    autoComplete: 'off'
  };
  const zone = getPcekZone();
  let optionsAnonymous;
  if (zone === 'anonymous') {
    optionsAnonymous = {
      headers: {
        'client-Id': 'mts-money-web-mtsid'
      }
    };
  }
  const [mobileServiceByPhoneId, setMobileServiceByPhoneId] = useState<string>(null);
  const [mobileServiceByPhoneError, setMobileServiceByPhoneError] = useState(null);
  const [mobileService, setMobileService] = useState<Payee>(null);
  const [mobileServiceError, setMobileServiceError] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  const isValidPhone = useMemo(() => !errors[MOBILE_PARAM_NAME] && formatPhoneNumber((values[field.name] as string), FormatAliasPhone.DIGIT11), [errors, field.name, values]);
  const {
    getBalance,
    cancelGetBalanceCustRequest
  } = useGetBalanceCust();
  const {
    getRecommendedAmountByPhone,
    cancelGetGetRecommendedAmountRequest
  } = useGetRecommendedAmountByPhone();
  const isBalanceRequired = isAuth && isMtsPhone(mobileService?.serviceId);
  const operatorIsNotDefinedError = !(isEmpty(mobileServiceByPhoneError) && isEmpty(mobileServiceError));

  // отслеживает изменение поля "Номер телефона"
  useEffect(() => {
    cancelGetBalanceCustRequest();
    cancelGetGetRecommendedAmountRequest();
    if (isValidPhone) {
      const formattedNumber = formatPhoneNumber((values[field.name] as string), FormatAliasPhone.DIGIT11);
      debounceGetPaymentService(formattedNumber);
      if (isAuth) {
        debounceGetRecommendedAmount(formattedNumber);
        debouncedGetBalance(formattedNumber);
      }
    }
  }, [values[field.name]]);

  // запрашивает рекомендованную сумму к оплату
  const debounceGetRecommendedAmount = debounce((phone: string) => {
    getRecommendedAmountByPhone({
      customerPhone: phone,
      onSuccess: data => setRecommendedAmount(data),
      onError: () => setRecommendedAmount(null)
    });
  }, 700, {
    leading: false,
    trailing: true
  });

  // запрашивает текущий баланс
  const debouncedGetBalance = debounce((phone: string) => getBalance({
    zone,
    scope: 'ALL',
    customerPhone: phone,
    onSuccess: data => setCurrentBalance(data?.accBalances[0]?.total?.amount),
    onError: () => setCurrentBalance(null)
  }), 700, {
    leading: false,
    trailing: true
  });
  const debounceGetPaymentService = debounce((phone: string) => {
    ewcatApi.getInfoMyMsisdnDo(zone, phone, optionsAnonymous).then(({
      data
    }) => {
      if (!data.errorCode && data.topUpServiceUniqueId) {
        setMobileServiceByPhoneId(data.topUpServiceUniqueId);
        setMobileServiceByPhoneError(null);
      } else {
        setMobileServiceByPhoneError(data);
      }
    }).catch(error => setMobileServiceByPhoneError(error));
  }, 700, {
    leading: false,
    trailing: true
  });
  useEffect(() => {
    if (operatorIsNotDefinedError) {
      setCurrentBalance(null);
    }
  }, [operatorIsNotDefinedError]);
  const fetchPayeeData = (id: string) => new Promise((res, rej) => {
    catalogManagerApi.getPayees(zone, uuid(), id, '', true, true, true, optionsAnonymous).then(result => {
      res(result.data.payees[0]);
    }).catch(error => rej(error));
  });
  useEffect(() => {
    if (mobileServiceByPhoneId) {
      fetchPayeeData(mobileServiceByPhoneId).then((payeeData: Payee) => {
        setMobileServiceError(null);
        setMobileService(payeeData);
      }).catch(e => setMobileServiceError(e));
    }
  }, [mobileServiceByPhoneId]);
  const checkServiceAfterBlur = useCallback(() => {
    if (!isEmpty(mobileService)) {
      handleChangeService();
    }
  }, [mobileService]);
  useEffect(() => {
    checkServiceAfterBlur();
  }, [mobileService, checkServiceAfterBlur]);
  const handlePhoneOnBlur = checkServiceAfterBlur;
  const handleChangeService = () => {
    if (mobileService && isValidPhone && !mobileServiceByPhoneError) {
      setPayeeData(mobileService);
    }
  };
  return <>
      <FieldsWrapper>
        <FieldWrapper>
          <Field {...field} component={PhoneField} onBlur={handlePhoneOnBlur} size="lg" />
        </FieldWrapper>
        {isBalanceRequired && !operatorIsNotDefinedError && <FieldWrapper>
            <Field allowDecimal readOnly value={currentBalance} component={MoneyInput} label="Текущий баланс" />
          </FieldWrapper>}
      </FieldsWrapper>
      <>
        {operatorIsNotDefinedError && <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs3}>
            <Text sizemob="md" color={theme.colors.red.active}>
              {operatorSelection}
            </Text>
          </Content>}
      </>
    </>;
};